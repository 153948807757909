<template>
  <div>
    <form>
      <b-form-file
        v-model="file1"
        :state="Boolean(file1)"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      />
      <b-img
        src="https://picsum.photos/1024/400/?image=41"
        fluid
        width="500"
        alt="fluid image"
      />
    </form>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
